

export default {
    init: function () {
        // JavaScript to be fired on all pages


        // Version 2
        $(window).scroll(function () {
            var scroll = $(window).scrollTop();
            if (scroll > 120) {
                $('.header').addClass('header__fix');
            } else {
                $('.header').removeClass('header__fix');
            }
        });

        // Jquery-match-height
        $(function () {
            setTimeout(function () {
                $('.match-height').matchHeight();
            }, 500);
        });

        jQuery(document).ready(function () {
            if ($(window).width() > 990) {
                jQuery('.sidebar__sticky').theiaStickySidebar({
                    additionalMarginTop: 150,
                });
            }
        });

        $(document).ready(function () {
            $('#js-agenda-filter').on('click', function () {
                let $button = $('.agenda__filter .searchandfilter > ul > li > ul > li:last-child input');

                $('.agenda__filter #js-agenda-filter').toggleClass('filter__on');

                if ($button.is(':checked')) {
                    $button.prop('checked', false);
                    $('.agenda__filter .searchandfilter').submit();
                } else if ($button.is(':not(:checked)')) {
                    $button.prop('checked', true);
                    $('.agenda__filter .searchandfilter').submit();
                }
            })
        });





        // News slider
        $('.news_slider').slick({
            dots: false,
            arrows: false,
            infinite: false,
            autoplay: true,
            autoplaySpeed: 8000,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
            },
                {
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
            },
            ],
        });

        // Agenda slider
        $('.agenda_slider').slick({
            dots: false,
            arrows: false,
            infinite: false,
            autoplay: true,
            autoplaySpeed: 8000,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
            },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
            },
            ],
        });

        // $(document).ready(function () {
        //     $('svg [id^=js-]').on('click', function () {
        //         $(this).find('[class^="pro-"]');
        //         console.log($(this).find('[class^="pro-"]'));
        //     })
        // });


        // Remove br on login links

        $('.gf_login_links br').remove();

        // Add styling to custom frontend login form button

        $('.page__content__inner__login .login-submit').append('<svg style="color: #fff;" aria-hidden="true" focusable="false" data-prefix="far" data-icon="angle-right" class="test1 svg-inline--fa fa-angle-right fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" d="M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z"></path></svg>\n');
        $('.page__content__inner__login .gf_login_form .gform_button').removeClass('button button-primary');
        $('.page__content__inner__login #wp-submit').addClass('btn btn-primary');

        $('.gf_login_form .gform_footer').append('<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="angle-right" class="test1 svg-inline--fa fa-angle-right fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" d="M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z"></path></svg>\n');
        $('.footer__column .nav li a').append('<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="angle-right" class="test1 svg-inline--fa fa-angle-right fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" d="M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z"></path></svg>\n');
        $('.page__sidebar__private__menu .nav li a').append('<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" class="svg-inline--fa fa-angle-right fa-w-8" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path></svg>\n');
        $('.page__sidebar__artsen__menu .fixed__menu__item__category').append('<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" class="svg-inline--fa fa-angle-right fa-w-8" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path></svg>\n');

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
    };
