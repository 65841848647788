$(document).ready(function() {

    // Slick slider banner
    $('.banner__slider').slick({
        autoplay: true,
        autoplaySpeed: 3000,
        draggable: true,
        arrows: false,
        dots: true,
        // customPaging: function (slick, index) {
        //     return '<button class="before-none" type="button" data-role="none" data-slick-index="' + index + '"><svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="circle" class="svg-inline--fa fa-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200z"></path></svg></button>';
        // },
        fade: true,
        speed: 900,
        infinite: true,
    });

});
