import JSZip from 'jszip/dist/jszip';
import { saveAs } from 'file-saver';

export default {}

/* eslint-disable */
let $url = vavData.root_url;
/* eslint-enable */

function saveToZip (filename, urls) {
    const zip = new JSZip()
    const folder = zip.folder('downloads')
    urls.forEach((url)=> {
        const blobPromise = fetch(url).then(r => {
            if (r.status === 200) return r.blob()
            return Promise.reject(new Error(r.statusText))
        })
        const name = url.substring(url.lastIndexOf('/'))
        const nameoptimzed = name.replace('/','');
        folder.file(nameoptimzed, blobPromise)
    })


    zip.generateAsync({type:'blob'})
        .then(blob => saveAs(blob, filename))
        .catch(e => console.log(e));
}

$('.btn-download-all').on('click', function () {
    let URLS = []
    console.log('test');
    $(this).closest('.downloads__list__inner').find('.downloads__list__item').each(function () {
        let $test = $(this).find('.downloads__list__btn .btn').attr('href');

        URLS.push($url + $test);
    })

    saveToZip('download.zip',URLS)
})

$('.btn-download-selected').on('click', function () {
    let URLS = []

    if ($(this).closest('.downloads__list__inner').find('.downloads__list__item').hasClass('selected')) {

        $(this).closest('.downloads__list__inner').find('.downloads__list__noting').removeClass('active');

        $(this).closest('.downloads__list__inner').find('.downloads__list__item.selected').each(function () {
            let $test = $(this).find('.downloads__list__btn .btn').attr('href');

            URLS.push($url + $test);
        });

        saveToZip('download-geselecteerd.zip',URLS)
    } else {
        $(this).closest('.downloads__list__inner').find('.downloads__list__noting').addClass('active');
    }
});

$('.downloads__list__click').on('click', function () {
    $(this).parent('.downloads__list__item').toggleClass('selected');
});

$('.download__archive__filterbtn').on('click', function () {
    $('body').addClass('filter__open')
});


$('.downloads__categories__inner__bg').on('click', function () {
    $('body').removeClass('filter__open')
});

$('.downloads__categories__list .btn').on('click', function () {
    $('body').removeClass('filter__open');
    $('.downloads__categories__list .btn').removeClass('active');
    $(this).addClass('active');
    let $getId = $(this).attr('data-catid');

    $('.downloads__list__inner').each(function () {
        let $getInnerId = $(this).attr('data-catid');
        if ($getInnerId == $getId) {
            $('.downloads__list__inner').hide();
            $(this).fadeIn()
        }
    })
})

$('.downloads__categories__list .btn-reset').on('click', function () {
    $('.downloads__categories__list .btn').removeClass('active');
    $('.downloads__list__inner').fadeIn()
})

;

$(document).ready(function() {
    if (window.location.href.indexOf('downloads/?') > -1) {
        window.location.hash = '#downloads__scroll';
    }
});
