$(document).ready(function() {

    // Variable
    var primaryMenu = $('.nav-primary .nav');
    var menuIcon = $('.menu__icon')
    var primaryOverlayTouch = $('.nav-primary__overlay');
    var touchEvents = $('.touchevents');
    var body = $('body')

    // Variable: primaryMenu
    // Add icon angle down to element with dropdown menu
    primaryMenu.find('> li.menu-item-has-children > a').append('<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="angle-down" class="svg-inline--fa fa-angle-down fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z"></path></svg>');
    primaryMenu.find('> li ul.sub-menu > li.menu-item-has-children > a').append('<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="angle-right" class="test1 svg-inline--fa fa-angle-right fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" d="M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z"></path></svg>');

    // Add class sub-menu1, sub-menu2 and submenu-3 to first, second and third dropdown menu
    primaryMenu.find('> li > ul.sub-menu').addClass('sub-menu1');
    primaryMenu.find('> li > ul.sub-menu > li > ul.sub-menu').addClass('sub-menu2');
    primaryMenu.find('> li > ul.sub-menu > li > ul.sub-menu li > ul.sub-menu').addClass('sub-menu3');

    primaryMenu.find('> li.menu-home > a').html('<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="home-lg" class="svg-inline--fa fa-home-lg fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M570.69 236.28L512 184.45V48a16 16 0 0 0-16-16h-64a16 16 0 0 0-16 16v51.69L314.75 10.31a39.85 39.85 0 0 0-53.45 0l-256 226a16 16 0 0 0-1.21 22.6L25.5 282.7a16 16 0 0 0 22.6 1.21L277.42 81.63a16 16 0 0 1 21.17 0L527.91 283.9a16 16 0 0 0 22.6-1.21l21.4-23.82a16 16 0 0 0-1.22-22.59zM288 115L69.47 307.71c-1.62 1.46-3.69 2.14-5.47 3.35V496a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16V368a16 16 0 0 1 16-16h96a16 16 0 0 1 16 16v128a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16V311.1c-1.7-1.16-3.72-1.82-5.26-3.2z"></path></svg>');

    // Variable: touchEvents
    touchEvents.find('.nav-primary .nav .menu-item-has-children').append('<div class="menu-item-has-children__overlay"></div>');
    touchEvents.find('.menu-item-has-children__overlay').on('click', function(){
        $(this).parent('.menu-item-has-children').addClass('menu-item-has-children__open');
    });

    // Variable: primaryOverlayTouch
    primaryOverlayTouch.on('click', function(){
        menuIcon.removeClass('open');
        body.removeClass('menu__open');
    });

    // Variable: menuIcon
    menuIcon.on('click', function(){
        $(this).toggleClass('open');
        body.toggleClass('menu__open');
    });
});
